<template>
  <fixed-header :threshold="300">
    <div
      id="V2HeaderWrap"
      class="V3HeaderWrap"
      :class="{
        dark_mode: $store.state.darkHeader,
        wide_mode: $store.state.wideHeader,
      }"
    >
      <div class="pc_header">
        <div class="bottom_header">
          <!-- mobile ======= -->
          <div
            class="mobile_menu_btn"
            @click="showMobileMenu = !showMobileMenu"
          >
            <v-icon large>mdi-menu</v-icon>
          </div>
          <div class="mobile_logo_box">
            <router-link to="/home">
              <template v-if="$store.state.darkHeader">
                <CImg
                  src="/img/threespace/logo/logo_white_thin.png"
                />
              </template>
              <template v-else>
                <CImg
                  src="/img/threespace/logo/logo_black_thin.png"
                />
              </template>
            </router-link>
          </div>

          <!-- pc ======= -->
          <div class="logo_box">
            <router-link to="/home">
              <template v-if="$store.state.darkHeader">
                <CImg
                  src="/img/threespace/logo/logo_white_thin.png"
                />
              </template>
              <template v-else>
                <CImg
                  src="/img/threespace/logo/logo_black_thin.png"
                />
              </template>
            </router-link>
          </div>
          <div class="right_menu_wrapper">
            <ul class="right_menu">
              <HeaderDropdownArtists />
              <router-link tag="li"  to="/ordinals" active-class="active">
                <a>{{ $t("menu.ordinals") }}</a>
              </router-link>
              <router-link tag="li"  to="/ai" active-class="active">
                <a>A.I.</a>
              </router-link>
              <router-link tag="li"  to="/meme" active-class="active">
                <a>MEME</a>
              </router-link>
              <router-link tag="li"  to="/little-picasso" active-class="active" class="shinhan">
                <a>
                  {{ $t("menu.picasso") }}
                  <div class="shinhan-logo"/>
                </a>
              </router-link>
              <HeaderDropdownArtpool />
              <HeaderDropdownAbout />
            </ul>
  
            <!-- 모바일에서도 사용중 -->
            <div>
              <HeaderDropdownWallet ref="headerDropdownWallet" />
            </div>
          </div>
        </div>
      </div>

      <!-- mobile menu ============ -->
      <div class="mobile_header" :class="{ menu_open: showMobileMenu }">
        <div class="mobile_menu_wrap">
          <section class="mobile_menu_header">
            <div class="logo_box">
              <router-link to="/home">
                <template v-if="$store.state.darkHeader">
                  <CImg
                    src="/img/threespace/logo/logo_white_thin.png"
                  />
                </template>
                <template v-else>
                  <CImg
                    src="/img/threespace/logo/logo_black_thin.png"
                  />
                </template>
              </router-link>
            </div>
            <button @click="onClickOutside">
              <img src="/img/threespace/icon/close.svg" />
            </button>
          </section>
          <ul class="main_menu">
            <li v-if="!isAuthenticated" class="btn-wrap justify-content-center">
              <b-button class="wallet-connect-btn" @click="showHeaderDropdownWallet">
                <span class="text-white">{{ $t("wallet.connect") }}</span>
              </b-button>
            </li>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("menu.artists") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/drops')">
                      {{ $t("menu.drops") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/artist')">
                      {{ $t("menu.list") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/apply')">
                      {{ $t("menu.apply") }}
                    </a>
                  </li>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <li class="menu">
              <a href="#" @click.prevent="toLinkInMobile('/ordinals')">
                {{ $t("menu.ordinals") }}
              </a>
            </li>
            <li class="menu">
              <a href="#" @click.prevent="toLinkInMobile('/ai')">
                A.I.
              </a>
            </li>
            <li class="menu">
              <a href="#" @click.prevent="toLinkInMobile('/meme')">
                MEME
              </a>
            </li>
            <li class="menu">
              <a class="shinhan" href="#" @click.prevent="toLinkInMobile('/little-picasso')">
                {{ $t("menu.picasso") }}
                <div class="shinhan-logo"/>
              </a>
            </li>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("menu.artPool") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/art-pool/nft/gallery')">
                      {{ $t("menu.artPoolSub.viewGallery") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/art-pool/nft')">
                      {{ $t("menu.artPoolSub.exhibit") }}
                    </a>
                  </li>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>3SPACE</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/about')">
                      {{ $t("menu.3spaceSub.about") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/art-pool/pace2.0')">$PACE</a>
                  </li>
                  <li>
                    <a href="https://docs.3space.art/" target="_blank">
                      {{ $t("menu.3spaceSub.whitePaper") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/features')">
                      {{ $t("menu.3spaceSub.features") }}
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="toLinkInMobile('/guide')">
                      {{ $t("menu.3spaceSub.guide") }}
                    </a>
                  </li>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </ul>
          <!--
          <ul class="sub_menu">
            <li v-if="!isAuthenticated">
              <a href="#" @click.prevent="toLinkInMobile('/login')">LOGIN</a>
            </li>
            <HeaderDropdownAccount v-if="isAuthenticated" :mobile-mode="true" />
          </ul>
          -->
          <section class="language_container">
            <button 
              @click="setActiveLanguage('ko')" 
              class="language_button" 
              :class="{ 'active': isActiveLanguage('ko') }"
            >KO</button>
            <button
              @click="setActiveLanguage('en')" 
              class="language_button" 
              :class="{ 'active': isActiveLanguage('en') }"
            >EN</button>
          </section>
        </div>
      </div>
    </div>
  </fixed-header>
</template>

<script>
import HeaderDropdownArtists from "@/containers/HeaderDropdownArtists";
import HeaderDropdownArtpool from "@/containers/HeaderDropdownArtpool";
import HeaderDropdownAbout from "@/containers/HeaderDropdownAbout";
//import HeaderDropdownAccount from "@/containers/HeaderDropdownAccount";
import HeaderDropdownWallet from "@/containers/HeaderDropdownWallet";
import { mapActions, mapGetters, mapState } from "vuex";
import vClickOutside from 'v-click-outside'
import FixedHeader from "vue-fixed-header";

export default {
  name: "Header",
  components: {
    HeaderDropdownArtists,
    HeaderDropdownArtpool,
    HeaderDropdownAbout,
    //HeaderDropdownAccount,
    HeaderDropdownWallet,
    vClickOutside,
    FixedHeader
  },
  data() {
    return {
      showMobileMenu: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.requestAccountBalance();
    });
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapState({
      me: state => {
        return state.auth.me || {};
      }
    }),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    currentPath() {
      return this.$route.path;
    }
  },
  methods: {
    ...mapActions("wallet", ["getBalance"]),
    showTerms(_code) {
      this.$router.push("/terms/" + _code);
    },
    setActiveLanguage(_lang) {
      localStorage.setItem("language", _lang);

      this.$emit("language-changed", _lang);
      window.location.reload();
    },
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    toLinkInMobile(link) {
      this.showMobileMenu = false;
      this.$router.push(link);
    },
    isActiveLanguage(_lang) {
      return this.activeLanguage === _lang;
    },
    requestAccountBalance() {
      this.getBalance().then(() => {});
    },
    onClickOutside() {
      this.showMobileMenu = false;
    },
    // Mobile
    showHeaderDropdownWallet() {
      this.$refs.headerDropdownWallet.showWalletPopupModal();
    }
  }
};
</script>

<style scoped>
.v-icon.v-icon {
  color: #000 !important;
}
</style>
