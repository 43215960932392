<template>
  <footer id="V2FooterWrap" class="footer">
    <section class="footer_nav">
      <div>
        <a href="https://medium.com/@3spaceart" target="_blank">
          <span> MEDIUM </span>
          <img src="/img/threespace/icon/external_link.png" />
        </a>
      </div>
      <div>
        <a href="https://twitter.com/3spaceart" target="_blank">
          <span> X(TWITTER) </span>
          <img src="/img/threespace/icon/external_link.png" />
        </a>
      </div>
      <div>
        <a href="https://t.me/threespaceart_chat" target="_blank">
          <span> TELEGRAM </span>
          <img src="/img/threespace/icon/external_link.png" />
        </a>
      </div>
      <div>
        <a href="https://www.instagram.com/3space.art/" target="_blank">
          <span> INSTAGRAM </span>
          <img src="/img/threespace/icon/external_link.png" />
        </a>
      </div>
      <div>
        <a href="https://github.com/3spaceart" target="_blank">
          <span> GITHUB </span>
          <img src="/img/threespace/icon/external_link.png" />
        </a>
      </div>
    </section>
    <section class="footer_wrap">
      <div>
        <div class="description__wrap">
          <div class="description">
            <p>CEO.</p>
            <p>{{ $t("menu.ceo") }}</p>
          </div>
          <div class="description">
            <p>TEL.</p>
            <p>{{ $t("menu.tel") }}</p>
          </div>
        </div>
        <div class="description">
          <p>ADDRESS.</p>
          <p>{{ $t("menu.address") }}</p>
        </div>
      </div>
      <div>
        <div class="href_wrapper">
          <a href="/terms/terms_of_service" target="_blank">{{
            $t("menu.terms")
          }}</a>
          <a href="/terms/privacy_policy" target="_blank">{{
            $t("menu.privacyPolicy")
          }}</a>
          <a href="/contact">{{ $t("menu.inquiries") }}</a>
        </div>
        <section class="language_container">
          <button
            @click="setActiveLanguage('ko')"
            class="language_button"
            :class="{ active: isActiveLanguage('ko') }"
          >
            KO
          </button>
          <button
            @click="setActiveLanguage('en')"
            class="language_button"
            :class="{ active: isActiveLanguage('en') }"
          >
            EN
          </button>
        </section>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  components: {},
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    setActiveLanguage(_lang) {
      localStorage.setItem("language", _lang);

      this.$emit("language-changed", _lang);
      window.location.reload();
    },
    isActiveLanguage(_lang) {
      return this.activeLanguage === _lang;
    },
  },
};
</script>
